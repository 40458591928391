<template>
  <div class="order">
    <div class="row">
      <div id="steps" class="col-md-4">
        <div class="order-background-1">
          <img :src="Register" />
        </div>
        <h5>Register</h5>
        <p>Fill the registration form Submit the required documents</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="Update" />
        </div>
        <h5>Update Account</h5>
        <p>Upload your menu and Set up your menu category options</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="sales" />
        </div>
        <h5>Manage Sales</h5>
        <p>Track your orders when Customers make purchase</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
    </div>

    <div class="row">
      <div id="steps" class="col-md-4">
        <div class="order-background-1">
          <img :src="orders" />
        </div>
        <h5>Customers Orders</h5>
        <p>You accept the order, and prepare the food</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="payment" />
        </div>
        <h5>Payment</h5>
        <p>Receive payment of order from customers</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="MyDelivery" />
        </div>
        <h5>Other Delivered</h5>
        <p>Jumia riders pick up the order and deliver it to the customer</p>
        <div class="arrow">
          <img :src="Arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSteps",
  data() {
    return {
      Register: require("../../../assets/CDelivery.png"),
      Update: require("../../../assets/Update.png"),
      sales: require("../../../assets/Tsales.png"),
      orders: require("../../../assets/orders.png"),
      payment: require("../../../assets/Payment-real.png"),
      MyDelivery: require("../../../assets/Delivery.png"),
      Arrow: require("../../../assets/arrow.png"),
    };
  },
};
</script>

<style scoped>
.row {
  --bs-gutter-x: 0rem;
}
.order {
  padding: 0px 00%;
  padding-top: 5%;
  /* text-align: center; */
}

.order h4 {
  color: rgba(4, 3, 15, 1);
}

.order p {
  color: rgba(67, 66, 76, 1);
  font-size: 14px;
}

#steps {
  padding: 0px 8%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

#steps:hover {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* width: 40%; */
}

.order-background {
  text-align: center;
}

.order-background img {
  /* background: rgba(255, 245, 238, 1); */
  padding: 10px;
  margin: 20px;
  text-align: center;
  justify-content: center;
}

.order-background-1 img {
  /* background: rgba(248, 248, 255, 1); */
  padding: 10px;
  margin: 20px;
}

.arrow {
  float: right;
}
</style>
