<template>
  <div>
    <Header />
    <main class="vendor-layout">
      <div class="body">
        <div>
          <Banner />
          <HowItWorks />
        </div>
        <Footer />
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../../components/ReusableUI/header.vue";
import Banner from "../../layouts/VendorPages/VendorComponents/Banner.vue";
import HowItWorks from "../../layouts/VendorPages/VendorComponents/HowItWorks.vue";
import Footer from "../../components/Home/Footer.vue";
export default {
  components: {
    Header,
    Banner,
    HowItWorks,
    Footer,
  },
};
</script>

<style scoped>
.vendor-layout {
  margin: 0 6%;
}

.body {
  overflow-y: scroll;
  height: calc(100vh - 60px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
</style>
