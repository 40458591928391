<template>
  <div class="banner-container">
    <div class="align-center">
      <Button
        id="banner-btn"
        @click="vendorRegister()"
        btnText="Become a Vendor"
      />
      <p id="banner-text">Reach new customers, get more sales</p>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/ReusableUI/SmalllButton.vue";
import router from "@/router";

export default {
  name: "Banner",
  data() {},

  components: {
    Button,
  },

  methods: {
    vendorRegister() {
      router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.banner-container {
  background-image: url("../../../assets/vend-background.png"),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.22) 46.5%,
      rgba(0, 0, 0, 0.8) 106.37%
    );
  height: 16em;
  border-radius: 25px;
  background-blend-mode: overlay;
  background-size: cover;
  text-align: center;
  margin-top: 5%;
}

#banner-btn {
  background-color: rgba(133, 77, 39, 1);
}

.align-center {
  width: 800px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-center p {
  color: #fff;
  font-size: 27px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  #banner-text {
    word-wrap: break-word;
  }
  #banner-text {
    font-size: 14px;
  }
}
</style>
